import React, { forwardRef } from "react";
import Icofont from "react-icofont";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dataTabs from "../../data/TabsIconSection/tabs-default-data.json";
import img from "../../assets/images/assetsfull.png";

const AboutArchitecture = forwardRef((props, ref) => (
  <section id="about" className="pb-80" ref={ref}>
    <div className="container">
      <div className="row">
        <div className="col-md-6 mb-30">
          <img className="img-fluid" src={img} alt="" />
        </div>

        <div className="col-md-6">
          <h2 className="text-uppercase font-700 mt-0">
            <span className="font-600">We are</span> <span className="add-css-colour-custom">Seventiment</span>
          </h2>
         <br />
         <h5> <span className="add-css-colour-custom-mid font-600">
          A wedding planner software designed to bring transparency and ease to the complex process of organizing weddings. 
          </span></h5><br/>
         
          {/* <div className="light-tabs mt-50">
            <ul className="nav nav-tabs text-left" role="tablist">
              <li role="presentation" className="active">
                <a href="#mission" role="tab" data-toggle="tab">
                  Mission
                </a>
              </li>
              <li role="presentation">
                <a href="#history" role="tab" data-toggle="tab">
                  History
                </a>
              </li>
              <li role="presentation">
                <a href="#vision" role="tab" data-toggle="tab">
                  Vision
                </a>
              </li>
            </ul>
            <div className="tab-content text-left">
              <div
                role="tabpanel"
                className="tab-pane fade in active"
                id="mission"
              >
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                  tincidunt consequat nunc, ultrices vehicula mauris mollis et.
                  Sed pharetra ultrices quam in laoreet. Donec laoreet eu mi nec
                  sagittis. Curabitur nunc velit, egestas vel aliquet ac, tempus
                  quis dolor.
                </p>
              </div>
              <div role="tabpanel" className="tab-pane fade" id="history">
                <p>
                  Duis pulvinar mattis turpis in mollis. Suspendisse eu eros id
                  dolor efficitur tincidunt quis id tellus. In lacinia laoreet
                  auctor. Mauris a consequat magna, in tempus urna laoreet eu mi
                  nec sagittis. Curabitur nunc velit, egestas vel aliquet ac,
                  tempus quis dolor.
                </p>
              </div>
              <div role="tabpanel" className="tab-pane fade" id="vision">
                <p>
                  Nam nisi sapien, varius non aliquet vel, vestibulum at lacus.
                  Nulla leo neque, mattis id risus id, imperdiet finibus risus.
                  Curabitur laoreet sit amet lacus vel semper. Vivamus in risus
                  magna. Nulla eu metus congue, condimentum mi et, tristique
                  arcu.
                </p>
              </div>
            </div>
          </div> */}
          {/* <a href="!#" className="btn btn-color btn-circle btn-animate mt-20">
            <span>
              Read more <Icofont icon="simple-right" />
            </span>
          </a> */}
        </div>
{/* 
        <Tabs className="light-tabs mt-30">
            <TabList className="nav nav-tabs text-left">
              {dataTabs
                .filter((v, i) => i < 4)
                .map((tab) => (
                  <Tab key={tab.id} className="nav-item react-tabs__tab">
                    <span>{tab.title}</span>
                  </Tab>
                ))}
            </TabList>
            <div className="tab-content text-left">
              {dataTabs
                .filter((v, i) => i < 3)
                .map((tab) => (
                  <TabPanel key={tab.id} className="tab-pane show in active">
                    <p
                      data-aos-delay={100}
                      data-aos={"fade-in"}
                    >
                      {tab.text}
                    </p>
                  </TabPanel>
                ))}
            </div>
          </Tabs> */}
      </div>
    </div>
  </section>
));

export default AboutArchitecture;
