import React from "react";
import Icofont from "react-icofont";
import dataDemos from "../data/Demos/demos-data.json";
import Loader from "../components/Loader/Loader";
import HeroDemo from "../components/Hero/HeroDemo";
import CTAThree from "../components/CTA/CTAThree";
import FooterCopyright from "../components/Footer/FooterCopyright";
import DemoParallax from "./DemoParallax";

import AppStyle from "./home/AppStyle";

const Demos = () => {
  return (
    <Loader>
      <AppStyle></AppStyle>
    </Loader>
  );
};

export default Demos;
